'use strict';

var redirectTicketMobiFacilImport = require('../ticket/redirecTicketMobiFacil');
var toggleModal = require('../components/toggleModal');

function showModalErrorSearch() {
    var errorMessage = $('#errorMessage').val();

    if (errorMessage) {
        var clearCart = errorMessage.indexOf('limpar seu carrinho') > -1;
        toggleModal.showError({ title: '😔 Algo deu errado.', message: errorMessage, clearCart });
    }
}

function getBannerBg(isProductPage) {
    if (isProductPage) {
        var bgBannerDesktop = $('.hero_bg.searchContent').data('bg-desktop') || 'none';
        var bgBannerMobile = $('.hero_bg.searchContent').data('bg-mobile') || 'none';
        $(':root').css({ '--bgBannerDesktop': 'url(' + bgBannerDesktop + ')', '--bgBannerMobile': 'url(' + bgBannerMobile + ')' });
    } else {
        var bgBannerDesktop = $('.homepage-buyTickets').data('bg-banner-desktop') || 'none';
        var bgBannerMobile = $('.homepage-buyTickets').data('bg-banner-mobile') || 'none';
        $(':root').css({ '--bgBannerDesktop': 'url(' + bgBannerDesktop + ')', '--bgBannerMobile': 'url(' + bgBannerMobile + ')' });
    }
}

module.exports = function () {
    $('#isStudent').on('change click', function () {
        var checkMark = $(this).next('.checkmark');
        var isCheck = $(this).is(':checked');
        if (isCheck) {
            checkMark.addClass('checked');
        } else {
            checkMark.removeClass('checked');
        }
    });

    $('#checkboxStudentDescription').on('click', function () {
        $('#isStudent').prop('checked', !$('#isStudent').prop('checked'));
    });
    $('#checkboxPcdDescription').on('click', function () {
        $('#isPCD').prop('checked', !$('#isPCD').prop('checked'));
    });

    $('#lastTripSearch').on('click', function () {
        window.location.href = $('#lastTripSearch').data('url');
    });


    $('.home__title').on('click', function () {
        window.scrollTo($('.last-order-wrapper.container'), {
            behavior: 'smooth'
        });
    });


    $('#lastOrder').on('click', function () {
        var obj = {
            origin: $('#originLastOrder').val(),
            destination: $('#destinationLastOrder').val(),
            departure: $('#inputDepartureLastOrder').val(),
            arrival: $('#inputBack').val(),
            service: $('#serviceLastOrder').val(),
            seat: $('#seatLastOrder').val(),
            isStudent: $('#isStudentLastOrder').prop('checked'),
            isPCD: $('#isPCD').prop('checked'),
            group: $('#groupLastOrder').val(),
            company: $('#companyLastOrder').val(),
            loyalty: '0'
        };

        if ($(this).data('show-login') === true) {
            $('form.login').attr('data-lastOrder', true);
            toggleModal.show('#login-modal');
            if (window.innerWidth < 800) {
                $('.modal-background').remove()
}
        }

        return obj;
    });

    $('.home-card-search-link').on('click', function (e) {
        $('body').trigger('busLoading:init');
        e.preventDefault();
        let elem = e.currentTarget;
        let token = document.querySelector('input[name="csrf_token"]')
            ? document.querySelector('input[name="csrf_token"]').value
            : '';
        let searchUrl = new URL($(elem).attr('href'));
        searchUrl.searchParams.append('csrf_token', token);
        window.location.href = searchUrl;
    });
    $(document).ready(function () {
        showModalErrorSearch();

        var tryMobifacil = async function () {
            if (location.href.toLowerCase().indexOf('itamarati') > -1) {
                var redirectTIcketMobiFacil = redirectTicketMobiFacilImport();

                if (
                    $('.error-message')[0] &&
                    redirectTIcketMobiFacil.isSearchable()
                ) {
                    var hasSearchedMobiFacil =
                        await redirectTIcketMobiFacil.hasSearchedOnMobiFacil();
                    if (hasSearchedMobiFacil) {
                        $('#ticketForm .modal').addClass('show');
                        redirectTIcketMobiFacil.setSearched(true);
                    }
                }
            }
        };

        tryMobifacil();
    });

    $('.search-tickets-btn').on('click', function (e) {
        e.preventDefault();

        var origin = $('#origin').val();
        var destination = $('#destination').val();
        var inputDeparture = $('#inputDeparture').val();

        var originInvalid = origin.length < 1;
        var destinationInvalid = destination.length < 1;
        var departureInvalid = inputDeparture.length < 1;

        if (originInvalid || destinationInvalid || departureInvalid) {
            if (originInvalid) {
                $('.cities__origin').addClass('is-invalid');
                $('.form-group--isOrigin .invalid-feedback').text('Insira a cidade de origem').show();
            } else {
                $('.cities__origin').removeClass('is-invalid');
                $('.form-group--isOrigin .invalid-feedback').text('').hide();
            }

            if (destinationInvalid) {
                $('.cities__destination').addClass('is-invalid');
                $('.form-group--isDestination .invalid-feedback').text('Insira a cidade de destino').show();
            } else {
                $('.cities__destination').removeClass('is-invalid');
                $('.form-group--isDestination .invalid-feedback').text('').hide();
            }

            if (departureInvalid) {
                $('#inputDeparture').addClass('is-invalid');
                $('#inputDeparture').siblings('.invalid-feedback').text('Informe uma data').show();
            } else {
                $('#inputDeparture').removeClass('is-invalid');
                $('#inputDeparture').siblings('.invalid-feedback').text('').hide();
            }

            setTimeout(function() {
                $('.is-invalid').removeClass('is-invalid');
                $('.invalid-feedback').text('').hide();
            }, 5000);

            return;
        }

        $('#ticketForm').submit();
    });



    $('.cities__origin, .cities__destination, .dates__departure').focus(
        function () {
            if (
                $('.cities__origin').val() != '' &&
                $('.form-group--isOrigin')
                    .find('.invalid-feedback')
                    .text()
                    .trim() == 'Insira a cidade de origem'
            ) {
                $('.form-group--isOrigin')
                    .find('.form-control.is-invalid')
                    .removeClass('is-invalid');
            }
            if (
                $('.cities__destination').val() != '' &&
                $('.form-group--isDestination')
                    .find('.invalid-feedback')
                    .text()
                    .trim() == 'Insira a cidade de destino'
            ) {
                $('.form-group--isDestination')
                    .find('.form-control.is-invalid')
                    .removeClass('is-invalid');
            }
            if (
                $('.dates__departure').val() != '' &&
                $('.form-group--isCalendar')
                    .find('.invalid-feedback')
                    .text()
                    .trim() == 'Informe uma data'
            ) {
                $('.form-group--isCalendar')
                    .find('.form-control.is-invalid')
                    .removeClass('is-invalid');
            }
        }
    );
    $('.cities__origin, .cities__destination, .dates__departure').blur(
        function () {
            if (
                $('.cities__origin').val() != '' &&
                $('.form-group--isOrigin')
                    .find('.invalid-feedback')
                    .text()
                    .trim() == 'Insira a cidade de origem'
            ) {
                $('.form-group--isOrigin')
                    .find('.form-control.is-invalid')
                    .removeClass('is-invalid');
            }
            if (
                $('.cities__destination').val() != '' &&
                $('.form-group--isDestination')
                    .find('.invalid-feedback')
                    .text()
                    .trim() == 'Insira a cidade de destino'
            ) {
                $('.form-group--isDestination')
                    .find('.form-control.is-invalid')
                    .removeClass('is-invalid');
            }
            if (
                $('.dates__departure').val() != '' &&
                $('.form-group--isCalendar')
                    .find('.invalid-feedback')
                    .text()
                    .trim() == 'Informe uma data'
            ) {
                $('.form-group--isCalendar')
                    .find('.form-control.is-invalid')
                    .removeClass('is-invalid');
            }
        }
    );

    $('.cities__origin').focus(function () {
        $('#destination_cities').css({ display: 'none' });
    });

    $('.cities__destination').focus(function () {
        $('#origin_cities').css({ display: 'none' });
    });


    // last order repeat behaviors

    $(document).ready(function () {
        // check if customer has some previous order
        if ($('#redirect-repeat-order').attr('href') === 'null') {
            // remove redirect link from repeat last order banner
            // eslint-disable-next-line no-script-url
            $('#redirect-repeat-order').attr('href', 'javascript:void(0)');

            // check if the page before login was triggered by repeat order banner
            if (window.location.queryString && window.location.queryString.repeatOrder) {
                // show empty previous order message
                $('.empty-trips-info').delay(1000).fadeIn();

                // scroll to banner component
                $('html, body').delay(1000).animate({
                    scrollTop: $('.last-order-wrapper.container').offset().top
                }, 'slow');

                // remove querystring from current url
                var newPath = window.location.pathname.split('?')[0];
                window.history.replaceState({}, document.title, newPath);
            }

            $('.home-page__block.last-order.container').on('click', function () {
                $('.empty-trips-info').fadeIn();
            });
        }
    });


    $('#empty-trips-info-msg').on('click', function () {
        window.scrollTo($('.home-page-header.container'), {
            behavior: 'smooth'
        });
        $('.form-control.cities__input.cities__origin').focus().click();
        $('.empty-trips-info').fadeOut();
    });

    // end of last order repeat behavior
    $('.cities__origin').focus(function () {
        $('#destination_cities').css({ display: 'none' });
    });

    $('.cities__destination').focus(function () {
        $('#origin_cities').css({ display: 'none' });
    });

    const isProductPage = window.location.href.includes('onibus-para');
    getBannerBg(isProductPage)

    window.addEventListener("Tracker Ready", function () {
        $(window).trigger('reservamos:interestInHome');
    });
};
